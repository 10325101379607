import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { postMethod } from "../crud";
import {
  BACKEND_URL,
  CONFIRM_SUBSCRIBE,
} from "../constants";

export const ConfirmSubscription = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const payload = searchParams.get("token");

    if (payload === null || payload === "") {
      setMessage("Token-ul este gol sau invalid");
      return;
    }

    postMethod(BACKEND_URL + CONFIRM_SUBSCRIBE, payload)
      .then((response) => {
        setMessage("Mail confirmat cu succes!");
      })
      .catch((error) => {
        setMessage("Token invalid");
      });
  }, []);

  return (
    <Grid container style={{ padding: "15px" }}>
      <Grid item xs={12} lg={10}>
        <Typography
          style={{
            fontFamily: "IBM BIOS",
            fontSize: "1rem",
            marginBottom: "20px",
          }}
        >
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};
