
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ErrorPage } from "./component/ErrorPage";
import { Archive } from "./component/Archive";
import { Magazine } from "./component/Magazine";
import { Article } from "./component/Article";
import { About } from "./component/About";
import { Subscribe } from "./component/Subscribe";
import { Unsubscribe } from "./component/Unsubscribe";
import { ConfirmSubscription } from "./component/ConfirmSubscription";
import { CategoryArticles } from "./component/CategoryArticles";
import { Root } from "./Root";


const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: <Archive />,
      },
      {
        path: '/magazine/:id',
        element: <Magazine />,
      },
      {
        path: '/article/:id',
        element: <Article />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/subscribe',
        element: <Subscribe />,
      },
      {
        path: '/unsubscribe',
        element: <Unsubscribe />,
      },
      {
        path: '/confirm-subscription',
        element: <ConfirmSubscription />,
      },
      {
        path: '/articles/:category',
        element: <CategoryArticles />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
