import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import '../styles/Article.css';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { useEffect, useState } from 'react';
import { getMethod } from '../crud';
import {
  ALREADY_LIKED,
  ARTICLE,
  ARTICLES,
  BACKEND_URL,
  cremComunist,
  LIKE,
  LIKES,
  LINK_BLUE,
  LINK_BLUE_HOVER,
  maroComunist,
  REDACTIA_CALCULATORISTUL,
} from '../constants';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useColorScheme } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';

export const Article = () => {
  const params = useParams();

  const [article, setArticle] = useState({
    id: '',
    title: '',
    content: '',
    category: '',
    writeDate: '',
    author: '',
    authorDepartment: '',
  });

  const [likes, setLikes] = useState(0);
  const [isAlreadyLiked, setIsAlreadyLiked] = useState(false);
  const [likeError, setLikeError] = useState(false);

  useEffect(() => {
    const result = getMethod(BACKEND_URL + ARTICLE + '/' + params.id);

    result
      .then((articleResponse) => {
        setArticle(articleResponse);

        getMethod(
          BACKEND_URL + ARTICLE + '/' + articleResponse.id + LIKES
        ).then((response) => {
          if (!!response) setLikes(response);

          getMethod(
            BACKEND_URL + ARTICLE + '/' + articleResponse.id + ALREADY_LIKED
          ).then((response) => {
            if (response !== undefined || response !== null)
              setIsAlreadyLiked(response);
            else setLikeError(true);
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const { mode, setMode } = useColorScheme();
  const theme = useTheme();

  function likeArticle() {
    setIsAlreadyLiked(!isAlreadyLiked);
    setLikes(isAlreadyLiked ? Math.max(likes - 1, 0) : likes + 1);
    getMethod(BACKEND_URL + ARTICLE + '/' + article.id + LIKE);
  }

  // Add custom component in markdown component for code blocks using react-syntax-highlighter
  // Based on the language, there are different color palettes
  // If the language is not found (eg. ``` code ```) block, apply custom styling
  // + added custom coloring for links
  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || ''); // search for language block
      return !inline && match ? (
        <SyntaxHighlighter
          children={String(children).replace(/\n$/, '')}
          style={oneDark}
          customStyle={{ fontSize: '.9rem' }}
          language={match[1]}
          PreTag='div'
          {...props}
        />
      ) : (
        <code
          className={className}
          {...props}
          style={{
            backgroundColor: mode === 'dark' ? '#656c7633' : '#f0f6fc',
            color: mode === 'dark' ? '#f0f6fc' : 'black',
            borderRadius: '5px',
            padding: '0 .2em',
          }}
        >
          {children}
        </code>
      );
    },
    a({ node, ...props }) {
      const linkColor = theme.palette.links.main;
      const hoverColor = theme.palette.links.hover;

      return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          {...props}
          style={{
            color: linkColor,
            textDecoration: 'none',
          }}
          onMouseEnter={(e) => {
            const target = e.target as HTMLAnchorElement;
            target.style.color = hoverColor;
          }}
          onMouseLeave={(e) => {
            const target = e.target as HTMLAnchorElement;
            target.style.color = linkColor;
          }}
        />
      );
    },
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          sx={{ fontSize: '.75rem', marginLeft: '10px' }}
          color='text.secondary'
          gutterBottom
        >
          <Link
            className='link'
            to={
              ARTICLES +
              '/' +
              encodeURIComponent(article.category.toLowerCase())
            }
          >
            {article.category}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <h2 className='magazine-title'> {article.title} </h2>
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: '.7rem',
            marginTop: '10px',
            marginLeft: '10px',
            color: '#995b50',
            fontFamily: 'IBM BIOS',
          }}
          gutterBottom
        >
          {article.author === REDACTIA_CALCULATORISTUL
            ? article.author + ' '
            : 'De ' + article.author + ' '}
          <span
            style={{
              fontFamily: 'serif',
              color: '#995b50',
              fontSize: '.9rem',
              fontWeight: 'bold',
            }}
          >
            |
          </span>{' '}
          {article.authorDepartment}
        </Typography>
        <Typography
          sx={{ fontSize: '.6rem', marginLeft: '10px', fontFamily: 'IBM BIOS' }}
          color='text.secondary'
          gutterBottom
        >
          {new Date(article.writeDate).toLocaleDateString('ro-ro', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
          <FavoriteIcon
            sx={{
              fontSize: '1.2rem',
              marginLeft: '1rem',
              marginRight: '.5rem',
              verticalAlign: 'bottom',
              '&:hover': {
                cursor: !likeError && 'pointer',
              },
              color: isAlreadyLiked && '#e31b23',
            }}
            onClick={() => {
              if (likeError) return;
              likeArticle();
            }}
          />
          {likes}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          padding: '15px',
          fontFamily: 'serif',
          fontSize: '1.2rem',
          lineHeight: '1.5',
        }}
      >
        {/* sr-only class is a hack to hide ugly footnotes title */}
        <Markdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={components}
        >
          {article.content}
        </Markdown>
      </Grid>
    </Grid>
  );
};
