import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './component/Header';
import Grid from '@mui/material/Grid2';
import './index.css';
import './styles/App.css';
import CookieConsent from 'react-cookie-consent';
import {
  CALM_BLACK,
  CALM_WHITE,
  cremComunist,
  LINK_BLUE,
  LINK_BLUE_HOVER,
  maroComunist,
} from './constants';
import {
  ThemeProvider,
  createTheme,
  useColorScheme,
} from '@mui/material/styles';
import { CssBaseline, Fab, useMediaQuery } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightModeRounded';

// TODO move this to its own file
const ToggleThemeButton = ({ mode, setMode }) => {
  return (
    <Fab
      onClick={() => {
        setMode(mode === 'dark' ? 'light' : 'dark');
      }}
      sx={{
        zIndex: 0 ,
        position: 'fixed',
        bottom: 20,
        right: 20,
        backgroundColor: mode === 'dark' ? 'white' : 'black',
        '&:hover': {
          backgroundColor: mode === 'dark' ? 'white' : 'black',
        },
      }}
      size='medium'
    >
      {mode === 'dark' ? (
        <LightModeIcon />
      ) : (
        <DarkModeIcon
          sx={{
            color: 'white',
          }}
        />
      )}
    </Fab>
  );
};

// TODO move this theme to another file
declare module '@mui/material/styles' {
  interface Palette {
    links: {
      main: string;
      hover: string;
    };
  }

  interface PaletteOptions {
    links?: {
      main: string;
      hover: string;
    };
  }
}

// TODO add color palette from constants.tsx
const theme = createTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          default: 'white',
        },
        text: {
          primary: CALM_BLACK,
        },
        links: {
          main: LINK_BLUE,
          hover: LINK_BLUE_HOVER,
        },
      },
    },
    dark: {
      palette: {
        background: {
          default: CALM_BLACK,
        },
        text: {
          primary: 'white',
        },
        links: {
          main: maroComunist,
          hover: cremComunist,
        },
      },
    },
  },
  // override input field to match color palette
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInput-root': {
            fontFamily: 'CORDATA',
            '&::after': {
              borderBottom: '2px solid #af7d5e',
            },
            '&.Mui-focused::after': {
              borderBottom: '2px solid #af7d5e',
            },
          },
        },
      },
    },
  },
});

export const Root = () => {
  return (
    <ThemeProvider theme={theme} noSsr>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};

const App = () => {
  const { mode, setMode } = useColorScheme();

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // set first time accessing site theme because default mode is system
  React.useEffect(() => {
    if (prefersDarkMode) setMode('dark');
    else setMode('light');
  }, []);

  return (
    <div style={{ margin: 0, padding: 0 }}>
      <CookieConsent
        buttonText={'Am înțeles'}
        buttonStyle={{ backgroundColor: cremComunist, color: '#433403' }}
      >
        Acest site folosește cookie-uri pentru a vă îmbunătăți experiența.
        Continuând să navigați pe acest site, sunteți de acord cu utilizarea
        cookie-urilor conform Politicii de utilizare a cookie-urilor.
      </CookieConsent>
      <Header />
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <Grid size={{ xs: 12, md: 8, lg: 7 }} className='debug-class'>
          <Grid container sx={{ marginTop: '5%' }}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
      <ToggleThemeButton mode={mode} setMode={setMode} />
    </div>
  );
};
