/**
 * 	Post Method Template for Api
 * @param url api endpoint
 * @param bodyContent payload
 * @param headerOptions
 * @returns data A promise to the data
 */
export async function postMethod(
  url,
  bodyContent,
  headerOptions = { 'Content-Type': 'application/json' }
) {
  const requestOptions = {
    method: 'POST',
    headers: headerOptions,
    body: bodyContent,
  };

  return fetch(url, requestOptions).then(async (response) => {
    const isJson = response.headers
      .get('content-type')
      ?.includes('application/json');

    const data = isJson && (await response.json());

    if (!response.ok) {
      const error = (data && data.message) || response.status;
      return Promise.reject(error);
    }

    return data;
  });
}

/**
 * Get Method Template for Api
 * @param url api endpoint
 * @param params endpoint parameters if exist
 * @returns data promise
 */
export async function getMethod(url, headerOptions = {}) {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...headerOptions,
    },
    credentials: 'include',
  };

  return fetch(url, requestOptions)
    .then(async (response) => {
      const isJson = response.headers
        .get('content-type')
        ?.includes('application/json');

      const data = isJson && (await response.json());

      if (!response.ok) {
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
      return data;
    })
    .catch((e) => {
      console.log(e);
      return null;
    });
}

export async function putMethod(
  url,
  bodyContent,
  headerOptions = { 'Content-Type': 'application/json' }
) {
  const requestOptions = {
    method: 'PUT',
    headers: headerOptions,
    body: bodyContent,
  };

  return fetch(url, requestOptions).then(async (response) => {
    const isJson = response.headers
      .get('content-type')
      ?.includes('application/json');

    const data = isJson && (await response.json());

    if (!response.ok) {
      const error = (data && data.message) || response.status;
      return Promise.reject(error);
    }

    return data;
  });
}
