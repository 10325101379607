import { Grid } from "@mui/material";
import "../index.css";
import "../styles/Archive.css";
import { MagazineNumbers } from "./MagazineNumbers";
import { BACKEND_URL, SEASONS, magazineName } from "../constants";
import { useEffect, useState } from "react";
import { getMethod } from "../crud";

// Show pretty archive box but my team didn't like it
const showArchive = false;

// Disable redundant magazine title in archive if we dont have a logo
const isLogo = false;

export const Archive = () => {
  // Format a season to be displayed in the archive
  // From api, a season is number like 2324
  // We want to display it like 2023-2024
  const formatSeason = (season) => {
    // Get the first 2 digits
    const firstYear = season.toString().slice(0, 2);

    // Get the last 2 digits
    const lastYear = season.toString().slice(2, 4);

    // Format the season
    // eslint-disable-next-line no-useless-concat
    return "20" + firstYear + "-" + "20" + lastYear;
  };

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    const result = getMethod(BACKEND_URL + SEASONS);

    result
      .then((response) => {
        setSeasons(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Grid container className="debug-class1">
      {isLogo && (
        <Grid item xs={12}>
          <h2 className="magazine-title"> {magazineName} </h2>
        </Grid>
      )}
      {showArchive && (
        <Grid item>
          <h3 className="archive-title"> ARCHIVES</h3>
        </Grid>
      )}
      <Grid item xs={12} className="debug-class">
        {seasons.map((season, i) => {
          return (
            <MagazineNumbers
              key={i}
              year={formatSeason(season.id)}
              numbers={season.magazines.filter((magazine) => {return magazine.draft === false})}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};
