import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "../styles/Header.css";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <Box sx={{ flexGrow: 1 }} className='appbar-style'>
      <AppBar position='static' sx={{ bgcolor: 'black' }}>
        <Toolbar className='toolbar-style'>
          {/* <Typography component="div" sx={{ flexGrow: 1 }}> */}
          <Link className='appbar-link' to='/'>
            <img src='/logo.png' alt='logo' className='header-logo' />
          </Link>
          {/* </Typography> */}
          <Link className='appbar-about' to='/about'>
            Despre noi
          </Link>
          |
          <Link
            className='appbar-about'
            to='https://forms.gle/q7m7APmp9PVo7AqH8'
          >
            Lasă-ne părerea ta
          </Link>
          |
          <Link className='appbar-about' to='/subscribe'>
            Abonează-te
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
